<template>
  <div class="base__content">
    <div class="content__directions" id="directions">
      <h1 class="content__title">Направления перевозки авто:</h1>
      <div class="content__links">
        <a href="#advantages" class="links__link" @click="openContentYakutsk">Владивосток-Якутск-Владивосток</a>
        <a href="#advantages" class="links__link" @click="openContentIrkutsk">Владивосток-Иркутск-Владивосток</a>
        <a href="#advantages" class="links__link" @click="openContentNovosibirsk">Владивосток-Новосибирск-Владивосток</a>
      </div>
      <div class="directions__partners">
        <h1 class="content__title-small">Наши партнеры:</h1>
        <div class="partners__links">
          <a href="https://jupiter-k.com/" target="_blank" class="links__link">
            <img :src="jupiterLogo">
          </a>
          <a href="https://www.vistaclubservice.ru/" target="_blank" class="links__link">
            <img :src="vistaLogo">
          </a>
        </div>
      </div>
    </div>
    <div class="content__links-block" v-if="contentBlocks">
      <div class="content__advantages" id="advantages">
        <h1 class="advantages__title-fr">Наши перимущества:</h1>
        <div class="advantages__wrapper">
          <div class="advantages__items">
            <div class="items__element">
              <font-awesome-icon :icon="['fas', 'handshake']" class="element-icon"/>
              <h1 class="element__title">Без переплат</h1>
              <p class="element__description">Мы работаем без посредников, и для наших клиентов мы готовы предоставить самую комфортную стоимость на перевозку автовозом</p>
            </div>
          </div>
          <div class="advantages__items">
            <div class="items__element">
              <font-awesome-icon :icon="['fas', 'clock']" class="element-icon"/>
              <h1 class="element__title">Регулярные перевозки авто</h1>
              <p class="element__description">Осуществляем регулярные рейсы по маршруту Владивосток - {{ city }} - Владивосток и обратно</p>
            </div>
          </div>
          <div class="advantages__items">
            <div class="items__element">
              <font-awesome-icon :icon="['fas', 'circle-check']" class="element-icon"/>
              <h1 class="element__title">Страховка перевозки авто</h1>
              <p class="element__description">Все грузоперевозки проходят обязательное страхование</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__info-description" id="info">
        <video id="myVideo" src="../assets/road.mp4" loop="true" autoplay="true" muted="true" style="width: 100%; height: auto; overflow: hidden;">
          <source type="video/mp4" src="../assets/road.mp4">
        </video>
        <div class="description__block">
          <h1 class="description__title">Факты о нас</h1>
          <div class="description__elements">
            <p class="elements__info">2000 <br> <span>машин перевезено</span></p>
            <p class="elements__info">3 <br> <span>года работы</span></p>
          </div>
        </div>
        <div class="info-description__overlay"></div>
      </div>
      <div class="content__text" id="stage">
        <h1 class="text__title">перегнать или автовозом ?</h1>
        <p class="text__description">
          Задумавшись о транспортировке автомобиля в другой город, владелец задается вопросом как его осуществить. Из доступных способов выбор падает на перегон, который на первый взгляд кажется дешевле и доступнее. На деле все наоборот.
          При планировании перегона часто забывают о временных затратах. Финансовые вложения обернутся расходами на бензин, покупкой еды в пути и арендой ночлега при длительном перегоне. Велик риск аварий, поломок, наматывается дополнительный пробег. Если водитель наемный, вряд ли его гарантии чем-то подкреплены. В итоге автомобиль, новый или бывший в употреблении, приезжает в Ваш город в лучшем случае потеряв товарный вид.
        </p>
        <h1 class="text__title-min">Как же перегнать из Владивостока в {{ city }} ?</h1>
          <div class="text__pros-blocks">
          <div class="pros__block">
            <h1 class="pros__title">Самостоятельный перегон авто:</h1>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-down']" class="pros-icon-dis"/>
                Риски
              </h1>
              <p>перегоняя авто самостоятельно, Вы берёте на себя все риски длительного перегона авто по загородной трассе</p>
            </div>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-down']" class="pros-icon-dis"/>
                Ресурс авто
              </h1>
              <p>кроме очевидных при перегонке авто затрат на бензин, многие автовладельцы не учитывают намотку автопробега</p>
            </div>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-down']" class="pros-icon-dis"/>
                Наемный водитель
              </h1>
              <p>большинство наёмных водителей - это просто знакомые, готовые перегнать авто, но не берущие на себя все риски перегона</p>
            </div>
          </div>
          <div class="pros__block">
            <h1 class="pros__title">Перегон авто на автовозе:</h1>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-up']" class="pros-icon-like"/>
                Страховка
              </h1>
              <p>мы страхуем принятый к перевозке автомобиль, выгодоприобретателем по которому, является сам клиент</p>
            </div>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-up']" class="pros-icon-like"/>
                Регулярный рейс
              </h1>
              <p>за 3 года перевозок по маршруту Владивосток - {{ city }} мы все доставки осуществили вовремя</p>
            </div>
            <div class="pros__item">
              <h1>
                <font-awesome-icon :icon="['fas', 'thumbs-up']" class="pros-icon-like"/>
                Официальный договор
              </h1>
              <p>предоставляем, подготавливаем и оформляем необходимый пакет документов для перевозки авто</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content__contacts">
      <div class="contacts__wrapper">
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab4217b3cfccdb5673ff1f12ea759bdb524689c0a275a2f017a8902022db3e997&amp;source=constructor" width="100%" height="722" frameborder="0"></iframe>
      </div>
      <div class="contacts__wrapper" id="contacts">
        <h1 class="content__title">Контакты</h1>
        <div class="contacts__info">
          <a href="tel:8-914-166-19-94" class="contacts__link">
            <font-awesome-icon :icon="['fas', 'phone']" class="contacts-icon" />
            <span>8-914-166-19-94</span>
          </a>
          <a href="https://wa.me/79141661994" target="_blank" class="contacts__link">
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="contacts-icon" />
            <span>Написать в Whatsapp</span>
          </a>
          <div class="contacts__requisites">
            <font-awesome-icon :icon="['far', 'clipboard']" class="requisites-icon" />
            <div class="requisites__info">
              <h1 class="contacts__link">Реквизиты:</h1>
              <h1 class="info__items">Полное наименование юридического лица: <span>Общество с ограниченной ответственностью «Автовоз 323»</span></h1>
              <h1 class="info__items">Адрес организации: <span>680042, Хабаровский край, г. Хабаровск, ул. Воронежская, д. 129, помещ. 20</span></h1>
              <h1 class="info__items">e-mail организации: <span>Avtovoz323@mail.ru</span></h1>
              <h1 class="info__items">ОГРН: <span>1222700006385</span></h1>
              <h1 class="info__items">ИНН: <span>2724249010</span></h1>
              <h1 class="info__items">КПП: <span>272401001</span></h1>
              <h1 class="info__items">Руководитель организации: <span>Директор Кузнецов Федор Викторович</span></h1>
              <h1 class="info__items">Р/сч  №: <span>40702 810 5 7000 0007548</span></h1>
              <h1 class="info__items">Кор/сч: <span>№ 30101 810 6 0000 0000608</span></h1>
              <h1 class="info__items">БИК БАНКА: <span>040813608</span></h1>
              <h1 class="info__items">НАИМЕНОВАНИЕ БАНКА: <span>Дальневосточный Банк ПАО Сбербанк</span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content__comments">
      <iframe style="width:760px;height:100%;border:1px solid #e6e6e6;border-radius:8px;box-sizing:border-box" src="https://yandex.ru/maps-reviews-widget/11860928478?comments"></iframe>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import jupiterLogo from '/src/assets/images/logo-jupiter.png';
import vistaLogo from '/src/assets/images/vista-logo.webp';

const contentBlocks = ref(false)
const city = ref('')

const emit = defineEmits(['update-content-blocks'])

function openContentYakutsk() {
  contentBlocks.value = true
  city.value = 'Якутск'
  emit('update-content-blocks', true)
}
function openContentIrkutsk() {
  contentBlocks.value = true
  city.value = 'Иркутск'
  emit('update-content-blocks', true)
}
function openContentNovosibirsk() {
  contentBlocks.value = true
  city.value = 'Новосибирск'
  emit('update-content-blocks', true)
}
</script>

<style lang="scss" scoped>
.base__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .content__title {
    font-size: 40px;
    font-weight: 700;

    @media (max-width: 1300px) {
      font-size: 30px;
    }

    @media (max-width: 520px) {
      font-size: 25px;
    }
  }

  .content__directions {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    background: #f3f1ed;
    width: 100%;
    padding: 100px 0;

    @media (max-width: 1300px) {
      height: auto;
      padding: 100px 0;
    }

    @media (max-width: 800px) {
      padding: 50px 0;
    }

    .content__links {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      column-gap: 100px;
      margin-top: 30px;
      max-width: 1200px;
      justify-content: center;
      justify-items: center;

      @media (max-width: 520px) {
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
      }

      .links__link {
        cursor: pointer;
        background: none;
        border: none;
        text-decoration: none;
        font-size: 30px;
        color: #8a8a8a;

        @media (max-width: 1300px) {
          font-size: 20px;
        }
      }
    }

    .directions__partners {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
      background: #fff;
      margin-top: 50px;
      padding: 30px;
      width: 100%;

      .content__title-small {
        font-size: 25px;
        font-weight: 600;
      }

      .partners__links {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;

        .link {}
      }
    }
  }

  .content__links-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content__advantages {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 300px;
      width: 100%;
      height: 700px;
      gap: 30px;

      @media (max-width: 1800px) {
        padding: 100px 200px;
        height: auto;
      }

      @media (max-width: 1300px) {
        padding: 50px 100px 0;
        height: auto;
      }

      @media (max-width: 800px) {
        padding: 50px 50px 0;
      }

      @media (max-width: 600px) {
        .advantages__title-fr {
          font-size: 25px;
        }
      }

      .advantages__title {
        font-size: 30px;
      }

      .advantages__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 1800px) {
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 900px;
          justify-content: center;
        }

        @media (max-width: 1300px) {
          width: 100%;
        }

        .advantages__items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          @media (max-width: 1800px) {
            width: auto;
          }

          .items__element {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            width: 400px;
            height: 500px;
            padding: 100px 20px;
            gap: 30px;

            @media (max-width: 1300px) {
              padding: 0;
              height: 400px;
            }

            @media (max-width: 500px) {
              width: 300px;
            }

            .element-icon {
              font-size: 170px;
              width: 170px;
              height: 170px;
              color: #5a5a5a;
            }

            .element__title {
              font-size: 20px;
              color: #5a5a5a;
            }

            .element__description {
              font-size: 15px;
              color: #5a5a5a;
              text-align: center;
            }
          }
        }
      }
    }

    .content__info-description {
      position: relative;

      .info-description__overlay {
        position: absolute;
        z-index: 996;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
      }

      .description__block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 998;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 100px;

        @media (max-width: 660px) {
          gap: 50px;
        }

        .description__title {
          font-size: 50px;
          color: #fff;

          @media (max-width: 660px) {
            font-size: 30px;
          }
        }

        .description__elements {
          display: flex;
          justify-content: center;
          gap: 200px;

          @media (max-width: 660px) {
            gap: 50px;
          }

          .elements__info {
            text-align: center;
            font-size: 80px;
            color: #fff;
            font-weight: 900;
            line-height: 40px;

            @media (max-width: 660px) {
              font-size: 50px;
            }

            span {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .content__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 700px;
      padding: 100px 300px;
      gap: 30px;

      @media (max-width: 1800px) {
        padding: 100px 200px;
        height: auto;
      }

      @media (max-width: 800px) {
        padding: 50px;
      }

      .text__title {
        font-size: 40px;
        text-transform: uppercase;

        @media (max-width: 1300px) {
          text-align: center;
        }
        
        @media (max-width: 800px) {
          font-size: 30px;
        }
      }

      .text__description {
        font-size: 20px;
        color: #8a8a8a;
        text-align: center;
        width: 95%;
        margin-bottom: 40px;

        @media (max-width: 800px) {
          font-size: 15px;
        }
      }

      .text__title-min {
        font-size: 30px;
        text-transform: uppercase;

        @media (max-width: 1300px) {
          text-align: center;
        }

        @media (max-width: 800px) {
          font-size: 20px;
        }
      }

      .text__pros-blocks {
        display: flex;
        align-items: center;
        gap: 100px;

        @media (max-width: 1300px) {
          flex-direction: column;
          gap: 50px;
        }

        .pros__block {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 500px;

          @media (max-width: 660px) {
            width: auto;
          }

          .pros__title {
            font-size: 30px;
            font-weight: 900;

            @media (max-width: 800px) {
              font-size: 20px;
            }
          }

          .pros__item {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h1 {
              display: flex;
              gap: 10px;
              font-size: 30px;

              @media (max-width: 800px) {
                font-size: 20px;
              }
              
              .pros-icon-dis {
                color: #e0304e;
                margin-top: 5px;
              }

              .pros-icon-like {
                color: green;
                
              }
            }

            p {
              color: #8a8a8a;
            }
          }
        }
      }
    }
  }

  .content__contacts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 50px;

    @media (max-width: 1300px) {
      flex-direction: column-reverse;
      gap: 0;
    }

    .contacts__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      height: 722px;

      @media (max-width: 1800px) {
        height: auto;
      }

      @media (max-width: 1300px) {
        width: 100%;
      }

      &:last-child {
        padding: 50px 0 50px;
        @media (max-width: 1800px) {
          padding-right: 50px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        @media (max-width: 800px) {
          padding-right: 0;
        }
      }

      .contacts__info {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 40px;
        margin-top: 40px;

        @media (max-width: 1300px) {
          padding: 0 100px;
          width: auto;
        }

        @media (max-width: 800px) {
          padding: 0 50px;
        }

        .contacts__link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #262323;
          font-size: 20px;
          gap: 40px;
          font-weight: 500;

          span {
            position: relative;
            overflow: hidden;

            &::before, &::after {
              content: '';
              position: absolute;
              bottom: 0;
              width: 0;
              height: 2px;
              background-color: #e0304e;
              transition: width 0.3s ease-in-out;
            }

            &::before {
              left: 50%;
            }

            &::after {
              right: 50%;
            }

            @media (hover: hover) {
              &:hover {
                &::before, &::after {
                  width: 50%;
                }
              }
            }
          }

          .contacts-icon {
            color: #e0304e;
            font-size: 50px;
            width: 50px;
            height: auto;

            @media (max-width: 570px) {
              width: 30px;
            }
          }
        }

        .contacts__requisites {
          display: flex;
          align-items: flex-start;
          gap: 40px;

          .requisites-icon {
            color: #e0304e;
            font-size: 50px;
            width: 50px;
            height: auto;

            @media (max-width: 570px) {
              width: 30px;
            }
          }

          .requisites__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            margin-top: 25px;

            @media (max-width: 570px) {
              margin-top: 15px;
            }

            .info__items {
              font-size: 15px;
              font-weight: 700;
              color: #8a8a8a;

              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .content__comments {
    display: flex;
    width: 100%;
    height: 700px;
    justify-content: center;
    background: #f3f1ed;
    padding: 50px;
  }
}
</style>